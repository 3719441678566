<template>
  <section class="head">
    <div
      class="bg"
      :style="{ backgroundImage: 'url(' + projectData.coverImg + ')' }"
    ></div>
    <div class="content">
      <div class="cover">
        <img :src="projectData.coverImg" alt="" />
      </div>
      <div class="text">
        <h1>{{ projectData.taskName }}</h1>
        <p>
          {{ $t('package.people_buy', [projectData.users]) }} 
          <!-- 人购买 -->
          <i>｜</i>
          {{ $t('package.good_rate') }} {{ projectData.praiseDegree }}%
          <!-- 好评度 -->
          <i>｜</i>
          <span class="heart" @click="favorite">
            <HeartOutlined
              style="margin-right: 4px"
              v-if="!projectData.isFavorite"
            />
            <HeartFilled style="margin-right: 4px; color: #ed640c" v-else />
            {{ $t('Pub_Lab_Collect') }}
            <!-- 收藏 -->
          </span>
        </p>
        <p v-if="!projectData.isBuy">
          <!-- 购买课程后，有效期为 -->
          {{ $t('package.after_buy_the_course') }}，{{ $t('package.is_valid_until') }} {{ projectData.effective }}
          <template v-if="projectData.dateType == 1">{{ $t('Pub_Day') }}</template>
          <!-- 天 -->
          <template v-if="projectData.dateType == 2">{{ $t('order.month') }}</template>
          <!-- 个月 -->
          <template v-if="projectData.dateType == 3">{{ $t('CM_Year') }}</template>
          <!-- 年 -->
        </p>
        <p class="time" v-else>
          <ClockCircleOutlined />
          {{ dateFormat(projectData.startTime, "YYYY-MM-DD") }} ~
          {{ dateFormat(projectData.endTime, "YYYY-MM-DD") }}
        </p>
        <div class="box">
          <span>
            {{ $t('package.personal_purchase', 2) }}：
            <!-- 个人购买： -->
            <strong v-if="projectData.price1 > 0">
              {{ $t('currency_symbol') }}
              {{ projectData.price1 }}
            </strong>
            <strong v-else>{{ $t('LB_NotCharge') }}</strong>
            <!-- 免费 -->
          </span>
          <span v-if="useEnterprise == 1 && projectData.price2 > 0">
            {{ $t('package.enterprise_purchase', 2) }}：
            <strong>{{ $t('currency_symbol') }} {{ projectData.price2 }}</strong>
            <!-- 单位购买： -->
            <i>
              {{ $t('package.min_buy') }}&nbsp;
              <!-- 单位最低购买 -->
              <em>{{ projectData.minPays }}</em>
              {{ $t('LB_My_Ge') }}
              <!-- 个 -->
            </i>
          </span>
        </div>
        <div class="btns" v-if="projectData.price1 > 0">
          <a-button v-if="!projectData.isBuy" type="primary" @click="jump(1)">
            {{ $t('package.personal_purchase', 1) }}
            <!-- 个人购买 -->
          </a-button>
          <a-button type="primary" @click="jump(2)" v-if="useEnterprise == 1">
            {{ $t('package.enterprise_purchase', 1) }}
            <!-- 单位购买 -->
          </a-button>
          <a-button
            v-if="!projectData.isBuy && useEnterprise == 1"
            @click="activateVisible = true"
          >
          {{ $t('package.code_exchange') }}
            <!-- 激活码兑换 -->
          </a-button>
          <a-button v-if="projectData.isBuy" @click="toStudy">
            {{ $t('LB_Cert_BeginLearn') }}
            <!-- 开始学习 -->
          </a-button>
        </div>
        <div class="btns" v-else>
          <a-button v-if="!projectData.isBuy" type="primary" @click="jump(3)">
            {{ $t('package.free_purchase') }}
            <!-- 免费购买 -->
          </a-button>
          <a-button v-if="projectData.isBuy" @click="toStudy">
            {{ $t('LB_Cert_BeginLearn') }}
            <!-- 开始学习 -->
          </a-button>
        </div>
      </div>
    </div>
  </section>

  <section class="mian">
    <a-spin :spinning="loadding">
      <div class="detail">
        <a-tabs>
          <!-- 详情介绍 -->
          <a-tab-pane key="1" :tab="$t('package.details')">
            <div
              v-if="projectData.intro"
              class="v-html"
              v-html="projectData.intro"
            ></div>
            <div style="padding: 40px 0" v-else>
              <a-empty :description="$t('LB_NoIntroduction')" />
              <!-- 暂无介绍 -->
            </div>
          </a-tab-pane>
          <!-- 目录 -->
          <a-tab-pane key="2" :tab="$t('LB_Course_Cate')">
            <div class="directory" v-if="stagesData.length">
              <div
                v-for="(stage, index) in stagesData"
                :key="stage.stageId"
                :id="'stage' + (index + 1)"
              >
                <div class="title">
                  <div class="headline">
                    <h5>
                      {{ stagesData[index].stageName }}
                    </h5>
                  </div>
                  <div class="intro" v-if="stagesData[index].intro">
                    {{ stagesData[index].intro }}
                  </div>
                </div>
                <ul class="list">
                  <li
                    v-for="item in stagesData[index].details"
                    :key="item.detailId"
                  >
                    <div class="tag-box">
                      <span
                        class="tag float"
                        v-if="
                          item.resourceType == 4 ||
                          item.resourceType == 5 ||
                          item.resourceType == 7
                        "
                      >
                        {{ resourceType[item.resourceType - 1].name }}
                      </span>
                      <span class="tag" v-else>
                        {{ resourceType[item.resourceType - 1].name }}
                      </span>
                    </div>
                    <div class="info">
                      <h5>{{ item.detailName }}</h5>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </a-tab-pane>
          <!-- 评论 -->
          <a-tab-pane
            v-if="hasComment"
            key="3"
            :tab="$t('Pub_Tab_CommentNum') + '（' + commentParams.Total + '）'"
            forceRender
          >
            <div style="padding: 14px 30px 10px 30px">
              <my-comment
                v-if="commentParams.resourceId > 0"
                :CommentParams="commentParams"
                disabled
              ></my-comment>
            </div>
          </a-tab-pane>
        </a-tabs>
      </div>
    </a-spin>
    <div class="recommend">
      <!-- 推荐培训包 -->
      <a-card :title="$t('package.recommend')" style="width: 290px">
        <template v-if="recommendList.length > 0">
          <a
            :href="`/package/introduce?id=${item.taskId}${
              newWindow == 1 ? '&ddtab=true' : ''
            }`"
            :target="newWindow == 1 ? '_blank' : '_self'"
            class="item"
            v-for="(item, index) in recommendList.slice(0, 5)"
            :key="index"
          >
            <img :src="item.coverImg" class="coverImg" />
            <div class="info">
              <p class="title">{{ item.taskName }}</p>
              <p class="price">{{ $t('currency_symbol') }} {{ item.price1 }}</p>
            </div>
          </a>
        </template>
        <a-empty v-else :description="$t('CM_Empty')" />
        <!-- 空空如也 -->
      </a-card>
    </div>
  </section>
  <!-- 激活码激活 -->
  <a-modal
    v-model:visible="activateVisible"
    :title="$t('package.activation_code_activation')"
    :maskClosable="false"
    :keyboard="false"
    :footer="null"
    width="440px"
  >
    <div class="activate-form">
      <a-form
        name="custom-validation"
        ref="formRef"
        :model="activateData"
        :rules="rules"
        @finish="handleFinish"
      >
      <!-- 激活码 -->
        <a-form-item :label="$t('package.form.activation_code')" name="activateCode">
          <a-input
            v-model:value="activateData.activateCode"
            size="large"
            autocomplete="off"
            :placeholder="$t('package.form.activation_code_p', [8])"
          />
          <!-- 请输入8位激活码 -->
        </a-form-item>
        <!-- 验证码 -->
        <a-form-item :label="$t('package.form.captcha')" name="captchaCode">
          <a-input
            style="padding-right: 130px"
            v-model:value="activateData.captchaCode"
            size="large"
            autocomplete="off"
            :placeholder="$t('package.form.请输入验证码')"
          />
          <!-- 请输入验证码 -->
          <img
            class="code"
            :src="activateCode.img"
            alt="code"
            @click="getCaptchaImg"
          />
        </a-form-item>
        <div class="btns">
          <a-button type="primary" html-type="submit">{{ $t('package.form.exchange') }}</a-button>
          <!-- 确认兑换 -->
          <a-button @click="activateVisible = false">{{ $t('CM_Cancel') }}</a-button>
          <!-- 取消 -->
        </div>
      </a-form>
    </div>
  </a-modal>
</template>

<script>
import { useI18n } from 'vue-i18n';
import { ref, getCurrentInstance, watch, computed } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { dateFormat } from "@/utils/tools";
import { resourceType } from "@/utils/business";
import {
  packageDetail,
  packageFavorite,
  packageRecommendList,
  packageActivate,
} from "@/api/package";
import { getCaptcha } from "@/api/other";
import { packagePayQr } from "@/api/package";
import myComment from "@/components/my-comment";
export default {
  components: {
    "my-comment": myComment,
  },
  setup() {
    const { t: $t } = useI18n();
    const { proxy } = getCurrentInstance();
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const taskId = Number(route.query.id);

    let useEnterprise = computed(
      () => store.getters.platformConfig.useEnterprise
    );

    let loadding = ref(true),
      hasComment = ref(false),
      projectData = ref({}),
      stagesData = ref([]),
      commentParams = ref({
        page: 1,
        pageSize: 10,
        resourceId: taskId || 0,
        type: 2, //0知识 1课程 2任务 3新闻
        taskType: 35, //任务类型参数 34培训项目 35培训包 37新员工 38课程专题
        Total: 0,
      });

    watch(
      () => store.getters.companyInfo,
      (company) => {
        hasComment.value = company.useComment == 1;
      },
      { immediate: true }
    );

    packageDetail(taskId).then((res) => {
      loadding.value = false;
      if (res.ret === 0) {
        projectData.value = res.data;
        stagesData.value = res.data.stages || [];
      } else {
        router.replace({
          path: `/error`,
          query: {
            title: $t('package.not_exist'),
            // 抱歉，您访问的培训包不存在。
            path: "/package",
            name: $t('cm_package'),
            // 培训包
          },
        });
      }
    });

    const favorite = () => {
      packageFavorite({ id: taskId }).then((res) => {
        projectData.value.isFavorite = res.data;
        if (res.data) {
          proxy.$message.success($t('LB_Doc_FavoriteSuccess'));
          // 收藏成功
        } else {
          proxy.$message.success($t('CM_CancelFavoriteSuccess'));
          // 取消收藏成功
        }
      });
    };

    const jump = (buyType) => {
      if (buyType === 1) {
        router.push({
          path: "./pay",
          query: {
            taskId,
            buyType,
            minPays: 1,
            price: projectData.value.price1,
          },
        });
      } else if (buyType === 2) {
        router.push({
          path: "./company",
          query: { taskId },
        });
      } else if (buyType === 3) {
        loadding.value = true;
        packagePayQr({
          buyType: 1,
          taskId,
          minPays: 1,
          price: 0,
        }).then((res) => {
          packageDetail(taskId).then((res) => {
            loadding.value = false;
            if (res.ret === 0) {
              projectData.value = res.data;
              projectData.value.isBuy = true;
              stagesData.value = res.data.stages || [];
              proxy.$message.success($t('package.purchase_success'));
              // 购买成功
            }
          });
        });
      }
    };

    const toStudy = () => {
      router.push({
        path: "./detail",
        query: { id: taskId },
      });
    };

    const recommendList = ref([]);
    packageRecommendList().then((res) => {
      if (res.ret == 0) {
        recommendList.value = res.data || [];
      }
    });

    const activateVisible = ref(false);
    const activateCode = ref({
      id: "",
      img: "",
    });
    watch(
      () => activateVisible.value,
      (val) => {
        if (val) getCaptchaImg();
      }
    );

    const getCaptchaImg = () => {
      getCaptcha().then((res) => {
        activateCode.value = res.data;
      });
    };

    const activateData = ref({
      activateCode: "",
      captchaCode: "",
    });

    const rules = {
      activateCode: [
        { required: true, message: $t('package.form.activation_code_p', [8]), trigger: "change" },
        // 请输入8位激活码
      ],
      captchaCode: [
        { required: true, message: $t('package.form.请输入验证码'), trigger: "change" },
        // 请输入验证码
      ],
    };

    const handleFinish = (values) => {
      packageActivate({
        activateCode: values.activateCode,
        captchaCode: values.captchaCode,
        captchaKey: activateCode.value.id,
        taskId,
      }).then((res) => {
        if (res.ret == 0) {
          projectData.value.isBuy = true;
          activateVisible.value = false;
          proxy.$message.success($t('package.form.activation_success'));
          // 激活成功
          setTimeout(() => {
            location.reload();
          }, 500);
        } else {
          getCaptchaImg();
        }
      });
    };

    return {
      newWindow: store.getters.companyInfo.useNewWindow,
      useEnterprise,
      dateFormat,
      resourceType,
      loadding,
      hasComment,
      projectData,
      stagesData,
      commentParams,
      recommendList,
      favorite,
      jump,
      toStudy,
      activateVisible,
      activateCode,
      activateData,
      rules,
      handleFinish,
      getCaptchaImg,
    };
  },
};
</script>

<style lang="less" scoped>
.head {
  padding: 42px 0;
  position: relative;
  .bg {
    .absolute-full();
    z-index: 1;
    background-size: cover;
    background-position: center;
    z-index: 1;
    overflow: hidden;
    &::before {
      content: "";
      position: absolute;
      top: -50px;
      right: -50px;
      bottom: -50px;
      left: -50px;
      background: inherit;
      filter: blur(30px);
      z-index: 2;
    }
    &::after {
      content: "";
      position: absolute;
      top: -50px;
      right: -50px;
      bottom: -50px;
      left: -50px;
      background-color: rgba(0, 0, 0, 0.2);
      z-index: 3;
    }
  }
  .content {
    .mixinWrap();
    .mixinFlex(space-between);
    .cover {
      z-index: 4;
      .mixinImgWrap(450px; 250px);
    }
    .text {
      z-index: 4;
      width: calc(100% - 488px);
      color: #fff;
      line-height: 1.5;
      h1 {
        font-size: 32px;
        font-weight: bold;
        line-height: 1.4;
        color: #fff;
        margin-bottom: 6px;
        word-break: break-all;
      }
      p {
        margin-bottom: 6px;
        font-size: 20px;
        i {
          padding: 0 6px;
        }
        .heart {
          cursor: pointer;
        }
      }
      .box {
        background-color: rgba(0, 0, 0, 0.25);
        // width: 576px;
        height: 56px;
        font-size: 20px;
        line-height: 56px;
        padding: 0 20px;
        margin-top: 10px;
        span {
          margin-right: 24px;
          strong {
            font-size: 22px;
            font-weight: normal;
            color: #e63450;
            &.money {
              &::before {
                content: "¥";
                font-size: 16px;
                margin-right: 2px;
              }
            }
          }
          i {
            padding-left: 2px;
            font-size: 14px;
            em {
              color: #e63450;
              padding: 0 2px;
            }
          }
        }
      }
      .btns {
        margin-top: 16px;
        .ant-btn {
          line-height: 50px;
          border: 1px solid #e63450;
          height: 50px;
          width: 160px;
          font-size: 22px;
          border-radius: 6px;
          color: #e63450;
          background: transparent;
          border-color: #e63450;
          padding: 0;
          margin-right: 12px;
        }
        .ant-btn-primary {
          background: #e63450;
          border-color: #e63450;
          color: #fff;
        }
        .ant-btn-primary[disabled],
        .ant-btn-primary[disabled]:hover,
        .ant-btn-primary[disabled]:focus,
        .ant-btn-primary[disabled]:active {
          color: rgba(0, 0, 0, 0.25);
          background: #f5f5f5;
          border-color: #d9d9d9;
        }
        .ant-btn[disabled],
        .ant-btn[disabled]:hover,
        .ant-btn[disabled]:focus,
        .ant-btn[disabled]:active {
          color: #d9d9d9;
          border-color: #d9d9d9;
        }
      }
    }
  }
}

.mian {
  padding-bottom: 64px;
  .mixinWrap();
  .mixinFlex(space-between);
  .detail {
    width: 890px;
    background-color: #fff;
    border-radius: 6px;
    margin-top: 24px;
    ::v-deep(.ant-tabs-nav-wrap) {
      padding: 0 30px;
    }
    ::v-deep(.ant-tabs-nav .ant-tabs-tab) {
      padding: 16px 0px;
      font-size: 16px;
      color: #999;
    }
    ::v-deep(.ant-tabs-nav .ant-tabs-tab-active) {
      color: @color-theme;
    }
    ::v-deep(.ant-tabs-ink-bar) {
      background-color: @color-theme;
    }
    .v-html {
      padding: 14px 30px 10px 30px;
    }
    .directory {
      padding: 14px 30px 10px 30px;
      .title {
        background-color: #f5f5f6;
        padding: 20px 24px;
        .headline {
          .mixinFlex(space-between; center);
          h5 {
            font-size: 16px;
            margin: 0;
            line-height: 1.5;
            width: calc(100% - 50px);
            span {
              padding-right: 16px;
            }
          }
        }
        .intro {
          margin-top: 6px;
          font-size: 14px;
        }
      }
      .list {
        li {
          .mixinFlex(space-between);
          border-bottom: 1px solid #eaf0ee;
          padding: 22px 24px 22px 38px;
          .tag-box {
            width: 100px;
            position: relative;
            .tag {
              width: max-content;
              height: 22px;
              padding: 0 4px;
              border: 1px solid @color-theme;
              color: @color-theme;
              font-size: 14px;
              border-radius: 2px;
              .mixinFlex(center; center);
              &.float {
                position: absolute;
                right: 0;
                top: 0;
              }
            }
          }
          .info {
            width: calc(100% - 60px);
            h5 {
              font-size: 16px;
              line-height: 22px;
              margin: 0;
            }
          }
          &:last-child {
            border-bottom: none;
          }
        }
      }
    }
  }
  .recommend {
    width: 290px;
    height: 100%;
    margin-top: 24px;
    .item {
      .mixinFlex(space-between);
      margin-bottom: 20px;
      .coverImg {
        .mixinImgWrap(120px; 70px);
        border-radius: 4px;
      }
      .info {
        width: calc(100% - 126px);
        .title {
          font-size: 14px;
          .mixinEllipsis(36px, 2);
          margin: 0;
        }
        .price {
          font-size: 16px;
          color: #e14c16;
          line-height: 20px;
          margin: 14px 0 0 0;
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.activate-form {
  .code {
    position: absolute;
    right: 0;
    top: 1px;
    width: 120px;
    height: 38px;
    cursor: pointer;
  }
  .btns {
    .mixinFlex(flex-end);
    .ant-btn {
      margin-left: 10px;
    }
  }
}
</style>
